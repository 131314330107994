import React, { useRef } from "react";
import SbEditable from "storyblok-react";

import { Title } from "../common/Titles";
import { getPalette } from "../../services/getPalette";
import { AnimateInRichText, FloatingText } from "../common/Animations";
import { FloatingImage } from "../common/FloatingImage";

// import { BackgroundDotsGlobe } from "../common/Dots/BackgroundDotsGlobe";
// import { BackgroundDots2 } from '../common/BackgroundDots2'

const FloatingItems = ({ blok, imageBefore }) => {
    // const description = typeof blok.description === 'string' ? blok.description : render(blok.description)
    const key = typeof blok._editable !== "undefined" ? blok._uid + "-edit" : blok._uid;
    const palette = getPalette(blok.palette);
    const refParent = useRef(null);

    return (
        <SbEditable content={blok} key={key}>
            <div data-scroll-section='true' className={"relative mt-1 bg-" + palette.background + " text-" + palette.text_main} ref={refParent}>
                {/* {blok.show_dot_background && <BackgroundDotsGlobe uniqueID={key} />} */}

                <div
                    className={
                        "-mt-1 container grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8  content-center " +
                        (imageBefore ? "pt-40 phablet:pt-52 laptop:pt-72 large:pt-96 pb-48" : "pt-36 phablet:pt-48  pb-10 phablet:pb-48")
                    }>
                    {/* Uses anchor link text to create an anchor link */}
                    {blok.anchor_link && <a id={blok.anchor_link} className='element' />}

                    <Title uniqueID={key} palette={palette} useSecondary={blok.use_secondary_color} text={blok.headline} refParent={refParent} />
                </div>

                {blok.items &&
                    blok.items.map((floatingitem, index) => {
                        // const delay = 100 + (index * 100)
                        let direction = "direction-rtl";
                        if (index % 2) {
                            direction = "direction-ltr";
                        }

                        return (
                            <div
                                key={index}
                                className={
                                    "container grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8  content-center pb-0"
                                }>
                                <div className={"hidden phablet:block phablet:col-span-1"} />
                                <div
                                    className={
                                        "col-span-6 phablet:col-span-10 relative grid grid-cols-6 phablet:grid-cols-10 gap-x-2 laptop:gap-x-4 large:gap-x-8 pt-10  mb-28 laptop:mb-56 pb-64 laptop:pb-0 " +
                                        direction
                                    }>
                                    <FloatingText
                                        uniqueID={key + index}
                                        text={floatingitem.bg_text}
                                        position={direction == "direction-rtl" ? "left" : "right"}
                                        palette={palette}
                                    />

                                    <div className={"col-span-6 phablet:col-span-5 relative"}>
                                        <FloatingImage
                                            uniqueID={key + index}
                                            floatingItem={floatingitem}
                                            palette={palette}
                                            position={direction == "direction-rtl" ? "left" : "right"}
                                        />
                                    </div>
                                    <div className={"col-span-6 phablet:col-span-4 direction-ltr mt-10 phablet:mt-0"}>
                                        <AnimateInRichText uniqueID={key + index} text={floatingitem.description} />
                                    </div>
                                </div>
                                <div className={"hidden phablet:block phablet:col-span-1"} />
                            </div>
                        );
                    })}
            </div>
        </SbEditable>
    );
};

export default FloatingItems;
