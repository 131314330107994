import React from "react";

export const ReadMore = ({ text, closedButtonText, openButtonText, palette, useSecondary }) => {
    const [isHidden, setIsHidden] = React.useState(true);

    return (
        <>
            <div
                className={
                    "cursor-pointer hover-aria white hover-aria-left  mb-5 text-" + (useSecondary ? palette.text_secondary : " ") + " font-bold"
                }
                aria-label={isHidden ? closedButtonText : openButtonText}
                onClick={() => setIsHidden(!isHidden)}>
                {isHidden ? closedButtonText : openButtonText}
            </div>
            {/* <SlideInText text={text} delayClass={('')} visible={isHidden ? true : false} fast={true} hideInstant={false} /> */}

            <div className={"ease-in-out-ios overflow-hidden block pt-3 " + (isHidden ? "max-h-0	" : "max-h-carousel")}>{text}</div>
        </>
    );
};
