import React from 'react';
import SbEditable from 'storyblok-react';
import DynamicComponent from '../DynamicComponent';

const Page = ({ blok, location }) => {
  // Used to add margin if the previous component has an image beneath it (divider image)
  let prevImageBelow = false;
  let thisImageBelow = false;

  const content =
    blok.body &&
    blok.body.map((childBlok) => {
      prevImageBelow = thisImageBelow;
      thisImageBelow = childBlok.image_below;
      return (
        <DynamicComponent
          location={location}
          blok={childBlok}
          key={childBlok._uid}
          isPrevImageDivider={prevImageBelow}
        />
      );
    });

  return (
    <SbEditable content={blok} key={blok._uid} location={location}>
      {content}
    </SbEditable>
  );
};

export default Page;
