import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import gsap from 'gsap'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import MapStyles from '../../css/map-styles'

const MaskedMap = (props) => {
  const mapWrapperRef = React.useRef(null)
  const [tl] = React.useState(gsap.timeline({ paused: false }))
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '0px 0px -30% 0px' })
  React.useEffect(() => {
    tl.fromTo(
      mapWrapperRef.current,
      {
        clipPath:
          'polygon(0% 200%, 0% 100%, 33% 100%, 33% 200%, 33% 150%, 66% 150%, 66% 200%, 66% 200%, 100% 200%, 100% 200%, 0% 200%)',
      },
      {
        clipPath:
          'polygon(0% 200%, 0% 0%, 33% 0%, 33% 200%, 33% 0%, 66% 0%, 66% 200%, 66% 0%, 200% 0%, 100% 200%, 0% 200%)',
        duration: 1,
        delay: 1,
      },
    )

    tl.fromTo(
      mapWrapperRef.current.children,
      {
        scale: 1.4,
      },
      {
        scale: 1,
        duration: 1,
        delay: 0.15,
      },
    )
  }, [tl])

  React.useEffect(() => {
    if (inView) {
      tl.play()
    }
  }, [inView, tl])

  const options = {
    zoom: 14,
    zoomControl: true,
    gestureHandling: 'none',
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    styles: MapStyles,
  }

  const containerStyle = {
    width: '100%',
    height: '100%',
  }

  const centers = [
    {
      lat: parseFloat(props.latitude),
      lng: parseFloat(props.longitude),
    },
    {
      lat: parseFloat(props.latitude),
      lng: parseFloat(props.longitude),
    },
  ]


  return (
    <div ref={ref} className={'w-full h-full'}>
      <div className={'w-full h-full'} ref={mapWrapperRef}>
        <LoadScript id='liminal-map' googleMapsApiKey='AIzaSyDcQgYzIaP9h94xi6QQoxD71SVw0n8tvGQ'>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={centers[0]}
            zoom={10}
            options={options}
            mapIds={['liminal-map']}
          >
            <Marker
              url={props.url}
              onClick={function () {
                window.open(props.url, '_blank').focus()
              }}
              icon={{
                path: 'M5.98998 -10H-5.45152C-5.86299 -9.99783 -6.25684 -9.84083 -6.54699 -9.56332C-6.83714 -9.28582 -7.00001 -8.91037 -7 -8.519V7.91184C-6.99773 8.30178 -6.83386 8.67513 -6.54395 8.95086C-6.25404 9.22659 -5.8615 9.38245 -5.45152 9.38461H5.98998C6.39996 9.38245 6.79251 9.22659 7.08241 8.95086C7.37232 8.67513 7.53619 8.30178 7.53846 7.91184V-8.52723C7.53619 -8.91717 7.37232 -9.29051 7.08241 -9.56624C6.79251 -9.84198 6.39996 -9.99784 5.98998 -10Z',
                fillColor: '#ffffff',
                fillOpacity: 0.9,
                scale: 2,
                strokeColor: 'white',
                strokeWeight: 6,
              }}
              position={centers[1]}
            />
            <Marker
              url={props.url}
              onClick={function () {
                window.open(props.url, '_blank').focus()
              }}
              icon={{
                path: 'M5.98998 -10H-5.45152C-5.863 -9.99783 -6.25684 -9.84083 -6.54699 -9.56332C-6.83714 -9.28582 -7.00001 -8.91037 -7 -8.519V7.91184C-6.99773 8.30178 -6.83386 8.67513 -6.54395 8.95086C-6.25404 9.22659 -5.8615 9.38245 -5.45152 9.38462H5.98998C6.39996 9.38245 6.79251 9.22659 7.08241 8.95086C7.37232 8.67513 7.53619 8.30178 7.53846 7.91184V-8.52723C7.53619 -8.91717 7.37232 -9.29051 7.08241 -9.56624C6.79251 -9.84198 6.39996 -9.99784 5.98998 -10ZM4.63181 5.98819C4.63181 6.33047 4.38785 6.60856 4.08681 6.60856H-0.622664C-0.92371 6.60856 -1.16766 6.33047 -1.16766 5.98819V4.88567L-2.04312 3.80454H-3.48606C-3.78711 3.80454 -4.03106 3.52644 -4.03106 3.18416V-6.6118C-4.03106 -6.95408 -3.78711 -7.23218 -3.48606 -7.23218H-1.73169C-1.43065 -7.23218 -1.18669 -6.95408 -1.18669 -6.6118V2.60329L-0.214345 3.80289H4.08681C4.38785 3.80289 4.63181 4.081 4.63181 4.42327V5.98819Z',
                fillColor: '#111C3E',
                fillOpacity: 0.9,
                scale: 2,
                strokeColor: 'white',
                strokeWeight: 0,
              }}
              position={centers[1]}
            />
            <></>
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  )
}

export default MaskedMap
