

export const getPalette = (palette) => {

  let paletteObj = {};

  switch (palette) {
  case 'palette1':
    paletteObj = { background: 'liminal-blue', text_main: 'liminal-white', text_secondary: 'liminal-blue-light' };
    break;
  case 'palette2':
    paletteObj = { background: 'liminal-blue', text_main: 'liminal-white', text_secondary: 'liminal-yellow' };
    break;
  case 'palette3':
    paletteObj = { background: 'liminal-ash', text_main: 'liminal-blue', text_secondary: 'liminal-blue-medium' };
    break;
  case 'palette4':
    paletteObj = { background: 'liminal-green', text_main: 'liminal-white', text_secondary: 'liminal-yellow' };
    break;
  case 'palette5':
    paletteObj = { background: 'liminal-green', text_main: 'liminal-white', text_secondary: 'liminal-ash' };
    break;
  case 'palette6':
    paletteObj = { background: 'liminal-blue-medium', text_main: 'liminal-white', text_secondary: 'liminal-blue-light' };
    break;
  case 'palette7':
    paletteObj = { background: 'liminal-blue-medium', text_main: 'liminal-white', text_secondary: 'liminal-blue' };
    break;
  case 'palette8':
    paletteObj = { background: 'liminal-white', text_main: 'liminal-blue', text_secondary: 'liminal-blue-medium'  };
    break;
  case 'palette9':
    paletteObj = { background: 'liminal-white', text_main: 'liminal-blue', text_secondary: 'liminal-blue' };
    break;
  default:
    paletteObj = { background: 'liminal-white', text_main: 'liminal-blue', text_secondary: 'liminal-blue' };
    break;
  }

  return paletteObj
}
