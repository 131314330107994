import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useLayout } from "../../hooks/useLayout";
import { useColumnWidth } from "../../hooks/useColumnWidth";
import { AnimateInSplitLines } from "./Animations";
import { useInView } from "react-intersection-observer";

export const HeroTitle = ({ uniqueId, text, animateIn, delay, start }) => {
    const firstWords = text.substr(0, text.indexOf(" "));
    const refFirstLine = React.useRef(null);
    const [tl] = React.useState(gsap.timeline({ paused: true }));

    const remainingLines = text.substr(text.indexOf(" ") + 1);
    const remainingLinesElements = <div>{remainingLines}</div>;

    React.useEffect(() => {
        let introTimeout = null;
        if (animateIn && start) {
            tl.from(refFirstLine.current, {
                yPercent: 100,
                autoAlpha: 0,
                duration: 0.7,
                ease: "Power3.easeOut",
            });

            // gsap.delayedCall((delay * 100 + 200), tl.play())
            introTimeout = setTimeout(function () {
                tl.play();
            }, delay);
        }
        return () => clearTimeout(introTimeout);
    }, [start]);

    return (
        <div className='col-span-6 phablet:col-span-12 laptop:col-span-10    grid grid-cols-6 phablet:grid-cols-10   gap-x-2 laptop:gap-x-4 large:gap-x-8   text-liminal-white tracking-wide text-left py-8 uppercase'>
            <h1 className='block phablet:block col-span-1 phablet:col-span-2'>&nbsp;</h1>
            <h1 className='col-span-5 phablet:col-span-8  laptop:col-span-8 overflow-hidden'>
                <div ref={refFirstLine}>{firstWords}</div>
            </h1>
            <h1 className='col-span-6 phablet:col-span-12  laptop:col-span-10'>
                {animateIn ? (
                    <AnimateInSplitLines
                        uniqueID={uniqueId + "hero-title"}
                        text={remainingLinesElements}
                        start={start}
                        delay={delay}
                        useScroll={false}
                    />
                ) : (
                    { remainingLines }
                )}
            </h1>
        </div>
    );
};

export const Title = ({ palette, useSecondary, text, smallTitle, halfWidth, instant, refParent }) => {
    const lines = text.split("\n");

    const { ref, inView, entry } = useInView({ triggerOnce: true, rootMargin: "0px 0px -10% 0px" });
    let [tl] = useState(gsap.timeline({ paused: true }));

    const { columnWidth } = useColumnWidth();

    React.useEffect(() => {
        if (!refParent || !refParent.current) return null;

        const animatedLines = refParent.current.querySelectorAll(".animated-line");
        const slideRightElements = refParent.current.querySelector(".slide-right");
        const slideLeftElements = refParent.current.querySelector(".slide-left");

        if (animatedLines) {
            tl.fromTo(
                animatedLines,
                { yPercent: 100 },
                {
                    yPercent: 0,
                    duration: 0.5,
                    ease: "Power3.easeInOut",
                    stagger: 0.1,
                    onComplete: () => {},
                }
            );

            gsap.delayedCall(0.1, () => {
                const tl_slide_right = gsap.timeline();
                tl_slide_right.fromTo(
                    slideRightElements,
                    { x: 0 },
                    {
                        duration: 0.8,
                        x: -columnWidth / 2,
                        scrollTrigger: {
                            trigger: refParent.current,
                            scrub: true,
                        },
                    }
                );

                const tl_slide_left = gsap.timeline();
                tl_slide_left.fromTo(
                    slideLeftElements,
                    { x: 0 },
                    {
                        duration: 0.8,
                        x: columnWidth / 2,
                        scrollTrigger: {
                            trigger: refParent.current,
                            scrub: true,
                        },
                    }
                );
            });
        }
    }, [tl]);

    useEffect(() => {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <div
            ref={ref}
            className={
                halfWidth
                    ? "col-span-6 phablet:col-span-6  uppercase font-bw-gradual grid grid-cols-6 phablet:grid-cols-6  gap-x-2 laptop:gap-x-4 large:gap-x-8  text-left pb-10"
                    : "col-span-6 phablet:col-span-12 grid grid-cols-6 phablet:grid-cols-12  uppercase font-bw-gradual  gap-x-2 laptop:gap-x-4 large:gap-x-8 text-left py-8 "
            }>
            {lines.map(function (line, index) {
                return (
                    <React.Fragment key={index + "-title"}>
                        {halfWidth ? (
                            smallTitle ? (
                                index < lines.length - 1 || lines.length === 1 ? (
                                    <h3 className={"col-span-6  slide-left  overflow-y-hidden	 phablet:col-span-6 text-" + palette.text_main}>
                                        <div className='animated-line'>{line}</div>
                                    </h3>
                                ) : (
                                    <>
                                        <h3 className={"col-span-1"} />{" "}
                                        <h3
                                            className={
                                                "col-span-5 slide-right phablet:col-span-5 overflow-y-hidden text-" +
                                                (useSecondary ? palette.text_secondary : palette.text_main)
                                            }>
                                            <div className='animated-line'>{line}</div>
                                        </h3>
                                    </>
                                )
                            ) : index < lines.length - 1 || lines.length === 1 ? (
                                <h1 className={"col-span-6 slide-left overflow-y-hidden	 phablet:col-span-5 text-" + palette.text_main}>
                                    <div className='animated-line'>{line}</div>
                                </h1>
                            ) : (
                                <>
                                    <h1 className={"col-span-1"} />{" "}
                                    <h1
                                        className={
                                            "col-span-5 slide-right phablet:col-span-5 overflow-y-hidden text-" +
                                            (useSecondary ? palette.text_secondary : palette.text_main)
                                        }>
                                        <div className='animated-line'>{line}</div>
                                    </h1>
                                </>
                            )
                        ) : smallTitle ? (
                            index < lines.length - 1 || lines.length === 1 ? (
                                <h3 className={"col-span-6 slide-left phablet:col-span-12 overflow-y-hidden	text-" + palette.text_main}>
                                    <div className='animated-line'>{line}</div>
                                </h3>
                            ) : (
                                <>
                                    <h3 className={"col-span-1"} />{" "}
                                    <h3
                                        className={
                                            "col-span-5 slide-right phablet:col-span-11 overflow-y-hidden text-" +
                                            (useSecondary ? palette.text_secondary : palette.text_main)
                                        }>
                                        <div className='animated-line'>{line}</div>
                                    </h3>
                                </>
                            )
                        ) : index < lines.length - 1 || lines.length === 1 ? (
                            <h1 className={"col-span-6 slide-left phablet:col-span-12 overflow-y-hidden	 text-" + palette.text_main}>
                                <div className='animated-line'>{line}</div>
                            </h1>
                        ) : (
                            <>
                                <h1 className={"col-span-1"} />{" "}
                                <h1
                                    className={
                                        "col-span-5 slide-right phablet:col-span-11 overflow-y-hidden text-" +
                                        (useSecondary ? palette.text_secondary : palette.text_main)
                                    }>
                                    <div className='animated-line'>{line}</div>
                                </h1>
                            </>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};
