import React, { useRef } from "react";
import SbEditable from "storyblok-react";

import { Title } from "../common/Titles";
import { getPalette } from "../../services/getPalette";
import { AnimateInRichText } from "../common/Animations";

const SimpleText = ({ blok, imageBefore }) => {
    const key = typeof blok._editable !== "undefined" ? blok._uid + "-edit" : blok._uid;
    const palette = getPalette(blok.palette);
    const refParent = useRef(null);

    return (
        <SbEditable content={blok} key={key}>
            <div data-scroll-section='true' className={"relative -mt-1 bg-" + palette.background + " text-" + palette.text_main}>
                <div
                    className={
                        "container grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8 " +
                        (imageBefore ? "pt-40 phablet:pt-52 laptop:pt-72 large:pt-96 pb-48" : "pt-36 phablet:pt-48  pb-48")
                    }
                    ref={refParent}>
                    {/* {/* Uses anchor link text to create an anchor link */}
                    {blok.anchor_link && <a id={blok.anchor_link} className='element' />}

                    <Title
                        uniqueID={key}
                        palette={palette}
                        useSecondary={blok.use_secondary_color}
                        smallTitle={blok.small_title}
                        text={blok.headline}
                        instant={blok.show_instantly}
                        halfWidth={false}
                        refParent={refParent}
                    />
                    <div className={"col-span-1 phablet:col-span-1 laptop:col-span-1 rich-text-block"} />

                    <div className={"col-span-6 phablet:col-span-10 laptop:col-span-5 rich-text-block mt-10"}>
                        <div className={"w-full text-" + palette.text_main}>
                            <AnimateInRichText uniqueID={key + "column1"} text={blok.left_col_text} instant={blok.show_instantly} />
                        </div>
                    </div>
                    <div className={"col-span-6 phablet:col-span-10 laptop:col-span-5 rich-text-block mt-10"}>
                        <div className={"w-full text-" + palette.text_main}>
                            <AnimateInRichText uniqueID={key + "column2"} text={blok.right_col_text} instant={blok.show_instantly} />
                        </div>
                    </div>
                    <div className={"hidden phablet:block phablet:col-span-1 laptop:col-span-1 "} />
                </div>
            </div>
        </SbEditable>
    );
};

export default SimpleText;
