import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";

import { render } from "storyblok-rich-text-react-renderer";
import SbEditable from "storyblok-react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";

import { Title } from "../common/Titles";
import { ReadMore } from "../common/ReadMore";
import { getPalette } from "../../services/getPalette";
import { AnimateInRichText } from "../common/Animations";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useInView } from "react-intersection-observer";

// import { BackgroundDots } from '../common/Dots/BackgroundDots'

const CarouselItems = ({ blok, imageBefore }) => {
    const key = typeof blok._editable !== "undefined" ? blok._uid + "-edit" : blok._uid;
    const palette = getPalette(blok.palette);

    const btnNext = React.useRef();
    const btnPrev = React.useRef();
    const swiperRef = React.useRef();
    const refParent = React.useRef();

    const { windowWidth } = useWindowDimensions();
    const [tl_intro] = React.useState(gsap.timeline({ paused: true }));
    const { ref, inView } = useInView({ triggerOnce: true, rootMargin: "0px 0px -10% 0px" });

    React.useEffect(() => {
        updateNavButtons();

        if (swiperRef.current) {
            // tl_intro.set(swiperRef.current.getElementsByClassName("swiper-image"), {
            //     clipPath: "polygon(0% 100%, 0% 100%, 100% 100%, 100% 100%)",
            // });
            // tl_intro.fromTo(
            //     swiperRef.current.getElementsByClassName("swiper-image"),
            //     { clipPath: "polygon(0% 100%, 0% 100%, 100% 100%, 100% 100%)" },
            //     {
            //         clipPath: "polygon(0% 100%, 0% 0%, 100% 0%, 100% 100%)",
            //         duration: 1.1,
            //         stagger: 0.2,
            //         ease: "Power3.easeInOut",
            //     },
            //     0
            // );

            const masks = swiperRef.current.querySelectorAll(".swiper-image-mask");

            tl_intro.to(masks, {
                yPercent: -101,
                stagger: 0.2,
                duration: 1.1,
                ease: "Power3.easeInOut",
            });

            tl_intro.fromTo(
                swiperRef.current.swiper.slides,
                { scale: 1.2, opacity: 0 },
                {
                    scale: 1,
                    opacity: 1,
                    duration: 1,
                    stagger: 0.2,
                    ease: "Power3.easeInOut",
                },
                0
            );

            tl_intro.from(
                btnNext.current,
                {
                    xPercent: 100,
                    opacity: 0,
                    duration: 0.5,
                    ease: "Power3.easeInOut",
                },
                1
            );
            tl_intro.from(
                btnPrev.current,
                {
                    xPercent: -100,
                    opacity: 0,
                    duration: 0.5,
                    ease: "Power3.easeInOut",
                },
                1
            );
        }
        updateSlides();
    }, []);

    const updateNavButtons = () => {
        if (swiperRef.current.swiper.isBeginning) {
            btnPrev.current.disabled = true;
        } else {
            btnPrev.current.disabled = false;
        }

        // To avoid going out of frame when you reach the end of the slider (on the right)
        if (swiperRef.current.swiper.isEnd) {
            btnNext.current.disabled = true;
        } else {
            btnNext.current.disabled = false;
        }
    };

    const pullNext = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
            updateNavButtons();

            // const cells = swiperRef.current.swiper.slides;
            // for (var i = 0; i < cells.length; i++) {
            //   cells[i].style.transitionTimingFunction = 'ease-in-out';
            //   cells[i].style.transitionDelay = (i * transitionDelay) + 'ms';;
            //   cells[i].classList.add('swiper-cell-move-left');
            // }

            // setTimeout(function () {
            //   swiperRef.current.swiper.slideNext()
            //   for (var i = 0; i < cells.length; i++) {
            //     cells[i].style.transitionTimingFunction = 'ease-in-out';
            //     cells[i].classList.remove('swiper-cell-move-left');
            //   }
            //   updateNavButtons();
            // }, (transitionDelay * (cells.length / 2)));
        }
    };

    const pullPrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
            updateNavButtons();

            // const cells = swiperRef.current.swiper.slides;
            // for (var i = 0; i < cells.length; i++) {
            //   cells[i].style.transitionTimingFunction = 'ease-in-out';
            //   cells[i].style.transitionDelay = ((cells.length * transitionDelay) - (i * transitionDelay)) + 'ms';
            //   cells[i].classList.add('swiper-cell-move-right');
            // }

            // setTimeout(function () {
            //   swiperRef.current.swiper.slidePrev()
            //   for (var i = 0; i < cells.length; i++) {
            //     cells[i].style.transitionTimingFunction = 'ease-in-out';
            //     cells[i].classList.remove('swiper-cell-move-right');
            //   }
            //   updateNavButtons();
            // }, (transitionDelay * (cells.length / 2)));
        }
    };

    React.useEffect(() => {
        updateSlides();
    }, [windowWidth, swiperRef]);

    useEffect(() => {
        if (inView) {
            tl_intro.play();
        }
    }, [inView, tl_intro]);

    const updateSlides = () => {
        if (swiperRef && swiperRef.current && swiperRef.current.swiper !== null) {
            if (windowWidth >= 1920) {
                const gap = 32;
                const column = 1440 / 12;

                swiperRef.current.swiper.params.slidesOffsetBefore = (windowWidth - 1440) / 2 + column + gap;
                swiperRef.current.swiper.params.slidesOffsetAfter = (windowWidth - 1440) / 2 - (column + gap);
                swiperRef.current.swiper.params.centeredSlides = false;
                swiperRef.current.swiper.params.slidesPerGroupAuto = true;
                swiperRef.current.swiper.params.slidesPerView = "auto";

                // swiperRef.current.swiper.params.spaceBetween = 30;

                swiperRef.current.swiper.update();
                swiperRef.current.swiper.slideTo(0);
            }

            if (windowWidth >= 1440 && windowWidth < 1920) {
                const gap = 32;
                const column = 1440 / 12;

                swiperRef.current.swiper.params.slidesOffsetBefore = (windowWidth - 1440) / 2 + column + gap;
                swiperRef.current.swiper.params.slidesOffsetAfter = (windowWidth - 1440) / 2 + column + gap;
                swiperRef.current.swiper.params.centeredSlides = false;
                swiperRef.current.swiper.params.slidesPerGroupAuto = true;
                swiperRef.current.swiper.params.slidesPerView = "auto";

                // swiperRef.current.swiper.params.spaceBetween = 30;

                swiperRef.current.swiper.update();
                swiperRef.current.swiper.slideTo(0);
            }

            if (windowWidth >= 1024 && windowWidth < 1440) {
                const gap = 15;
                const column = (windowWidth - 30) / 12;
                swiperRef.current.swiper.params.slidesOffsetBefore = column + column + gap + gap;
                swiperRef.current.swiper.params.slidesOffsetAfter = column + column + gap + gap;
                swiperRef.current.swiper.params.centeredSlides = false;
                swiperRef.current.swiper.params.slidesPerGroupAuto = true;
                swiperRef.current.swiper.params.slidesPerView = "auto";

                // swiperRef.current.swiper.params.spaceBetween = 12;
                swiperRef.current.swiper.update();
                swiperRef.current.swiper.slideTo(0);
            }

            if (windowWidth < 1024) {
                swiperRef.current.swiper.params.slidesOffsetBefore = 0;
                swiperRef.current.swiper.params.slidesOffsetAfter = 0;
                swiperRef.current.swiper.params.centeredSlides = true;
                swiperRef.current.swiper.params.slidesPerGroupAuto = false;
                swiperRef.current.swiper.params.slidesPerView = "auto";
                // swiperRef.current.swiper.params.spaceBetween = 10;

                swiperRef.current.swiper.update();
                swiperRef.current.swiper.slideTo(0);
            }
        }
    };

    return (
        <SbEditable content={blok} key={key}>
            <div data-scroll-section='true' className={"relative -mt-1 bg-" + palette.background + " text-" + palette.text_main}>
                <div
                    className={
                        "container grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8 " +
                        (imageBefore ? "pt-40 phablet:pt-52 laptop:pt-72 large:pt-96" : "pt-36 phablet:pt-48  ")
                    }
                    ref={refParent}>
                    {/* Uses anchor link text to create an anchor link */}
                    {blok.anchor_link && <a id={blok.anchor_link} className='element' />}

                    <Title
                        uniqueID={key}
                        palette={palette}
                        useSecondary={blok.use_secondary_color}
                        smallTitle={blok.small_title}
                        text={blok.headline}
                        refParent={refParent}
                    />
                    <div className={"col-span-1 phablet:col-span-6 laptop:col-span-6 rich-text-block"} />
                    <div className={"col-span-5 phablet:col-span-6 laptop:col-span-5 rich-text-block"}>
                        <div className={"w-full text-" + palette.text_main}>
                            <AnimateInRichText uniqueID={key} text={blok.intro_text} />
                        </div>
                    </div>
                    <div className={"hidden laptop:block laptop:col-span-1 "} />
                </div>
                <div className={"block relative pt-20 pb-48"}>
                    <div className={"relative"} ref={ref}>
                        <Swiper
                            className={"swiper-container"}
                            slidesPerGroupAuto={false}
                            simulateTouch={true}
                            centeredSlides={true}
                            speed={400}
                            ref={swiperRef}>
                            {/* <SwiperSlide className={'mr-12'}>
                <div className="" style={{ width: '320px', height: '440px' }} />
              </SwiperSlide> */}

                            {blok.items.map((item, index) => {
                                const description = typeof item.description === "string" ? item.description : render(item.description);
                                let fluidImageProps = null;
                                if (item.image.filename) {
                                    fluidImageProps = getFluidGatsbyImage(item.image.filename, {
                                        maxWidth: 960,
                                        maxHeight: 1320,
                                        format: "webp",
                                        toFormat: "webp",
                                        quality: "80",
                                    });
                                }

                                return (
                                    <SwiperSlide key={index} className={"mr-2 laptop:gap-x-4 large:gap-x-8"}>
                                        <div className={index % 2 == 0 ? "" : "mt-24"}>
                                            <div className='mb-6   swiper-image' style={{ width: "320px", height: "440px" }}>
                                                {fluidImageProps ? (
                                                    <div className='relative'>
                                                        <div
                                                            className={`absolute w-full h-full bg-${palette.background} inset-0 z-2 swiper-image-mask`}></div>
                                                        <Img objectFit='cover' objectPosition='50% 50%' fluid={fluidImageProps} />
                                                    </div>
                                                ) : (
                                                    <div className={"w-full h-full bg-" + palette.text_secondary} />
                                                )}
                                            </div>
                                            <div style={{ width: "320px" }}>
                                                <h5 className='mb-0'>{item.title}</h5>
                                                <div className={"mb-5 text-" + palette.text_secondary}>{item.sub_title}</div>
                                                {description && (
                                                    <ReadMore
                                                        text={description[0]}
                                                        closedButtonText={blok.show_more_text}
                                                        openButtonText={blok.hide_more_text}
                                                        palette={palette}
                                                        useSecondary={blok.use_secondary_color}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <button
                            ref={btnPrev}
                            className='swiper-button swiper-prev-next-button previous z-10'
                            aria-label='Previous'
                            onClick={pullPrev}
                        />
                        <button
                            ref={btnNext}
                            className='swiper-button swiper-prev-next-button next z-10 bg-liminal-blue'
                            aria-label='Next'
                            onClick={pullNext}
                        />
                    </div>
                </div>
            </div>
        </SbEditable>
    );
};

export default CarouselItems;
