import React from "react";
import Placeholder from './common/Placeholder'
import Careers from './repeatable/Careers'
import CareersManual from './repeatable/CareersManual'
import CarouselItems from './repeatable/CarouselItems'
import FloatingItems from './repeatable/FloatingItems'
import FormContactNetlify from './repeatable/FormContactNetlify'
import Hero from './repeatable/Hero'
import ImageGrid from './repeatable/ImageGrid'
import ImageMapText from './repeatable/ImageMapText'
import ImageText from './repeatable/ImageText'
import Section from './repeatable/Section'
import SimpleText from './repeatable/SimpleText'
import TabCarousel from './repeatable/TabCarousel'

const Components = {
  hero: Hero,
  section: Section,
  'tab-carousel': TabCarousel,
  'carousel-items': CarouselItems,
  'image-text': ImageText,
  'image-2col-text': ImageMapText,
  'floating-items': FloatingItems,
  'form-contact': FormContactNetlify,
  'simple-text': SimpleText,
  'image-grid': ImageGrid,
  careers: Careers,
  'careers-manual': CareersManual,
}

const DynamicComponent = ({ blok, isPrevImageDivider }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    let imageBefore = false
    isPrevImageDivider ? (imageBefore = true) : (imageBefore = false)

    return <Component blok={blok} key={blok._uid} imageBefore={imageBefore} />
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null
}

export default DynamicComponent
