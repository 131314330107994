import gsap from 'gsap'
import React from 'react'
import SbEditable from 'storyblok-react'
// import res from '../../pages/api'
import { getPalette } from '../../services/getPalette'
import { AnimateInRichText } from '../common/Animations'
// import DynamicComponent from '../DynamicComponent'
import Button from '../common/Button'
// import { BackgroundDots } from '../common/Dots/BackgroundDots'
import { useInView } from 'react-intersection-observer'
import { Title } from '../common/Titles'

const CareersManual = ({ blok, imageBefore }) => {
  // const body = blok &&
  // blok.body &&
  // blok.body.map((childBlok) => <DynamicComponent blok={childBlok} key={childBlok._uid} />)
  const refParent = React.useRef()

  const key = typeof blok._editable !== 'undefined' ? blok._uid + '-edit' : blok._uid
  const palette = getPalette(blok.palette)
  const careersID =
    'slider_' +
    blok.headline
      .replace(/(\r\n|\n|\r)/gm, '')
      .replace(/ /g, '')
      .toString(36)
      .substr(2, 9)

  const [tl_intro] = React.useState(gsap.timeline({ paused: true }))
  const [careersTitle, setCareersTitle] = React.useState(blok.headline)

  const careersRef = React.useRef()
  const buttonRef = React.useRef()

  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '0px 0px -10% 0px' })

  React.useEffect(() => {
    var children = careersRef.current.children

    tl_intro.from(children, {
      yPercent: 100,
      autoAlpha: 0,
      duration: 0.7,
      ease: 'Power3.easeOut',
      stagger: 0.1,
      delay: 0.2,
    })
  }, [])

  React.useEffect(() => {
    if (inView) {
      console.log('in view')
      tl_intro.play()
    }
  }, [inView, tl_intro])

  return (
    <SbEditable content={blok} key={key}>
      <div ref={refParent} data-scroll-section className={'relative -mt-1 bg-' + palette.background + ' text-' + palette.text_main + ' pb-40 phablet:pb-52 laptop:pb-72 large:pb-96'}>
        {/* <BackgroundDots /> */}

        <div
          ref={ref}
          className={
            'container grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8 ' + (imageBefore ? 'pt-40 phablet:pt-52 laptop:pt-72 large:pt-96' : 'pt-36 phablet:pt-48  ')
          }
        >
          {/* Uses anchor link text to create an anchor link */}
          {blok.anchor_link && <a id={blok.anchor_link} className='element' />}

          <Title uniqueID={key + 'headline'} palette={palette} useSecondary={blok.use_secondary_color} smallTitle={blok.small_title} text={careersTitle} refParent={refParent} />

          <div className={'col-span-1 phablet:col-span-6 laptop:col-span-6 rich-text-block'} />

          <div className={'col-span-5 phablet:col-span-6 laptop:col-span-5 rich-text-block'}>
            <div className={'w-full text-' + palette.text_main}>
              <AnimateInRichText uniqueID={key} text={blok.intro_text} />
            </div>
          </div>
          <div className={'hidden laptop:block laptop:col-span-1 '} />
        </div>
        <div className={'hidden phablet:block phablet:col-span-1 tablet:col-span-1 laptop:col-span-1 large:col-span-1'} />
        <div
          data-scroll
          data-scroll-call={careersID}
          data-scroll-offset='30%, 85%'
          ref={careersRef}
          className={'container careers-list grid grid-cols-6 pt-18 phablet:grid-cols-12 pt-12  gap-x-2 laptop:gap-x-4 large:gap-x-8'}
        >
          {blok.job_items &&
            blok.job_items.map((career, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={'hidden phablet:block laptop:col-span-1 '} />
                  <a
                    href={career.link.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={'careers-item col-span-6 mt-12 phablet:col-span-10 laptop:col-span-10  grid grid-cols-6 phablet:grid-cols-10   gap-x-2 laptop:gap-x-4 p-3'}
                  >
                    <div className={'col-span-4 flex flex-col laptop:col-span-8'}>
                      <p className={'col-span-6 laptop:col-span-8 careers-list_job-title'}>{career.title}</p>
                      <div className={'col-span-6 flex laptop:col-span-5 careers-list_job-description'}>
                        <p>{career.location}</p>
                        <span>•</span>
                        <p>{career.type}</p>
                      </div>
                    </div>
                    <div className={'col-span-2 flex flex-col laptop:col-span-2 items-end'} ref={buttonRef}>
                      <div className='external-link-elements external-link-active-state pr-3'>
                        <span className='external-link-text'>Apply</span>
                        <svg width='10' height='10' viewBox='0 0 10 10' fill='#9CD3EE' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M9.28568 0H3.5714C3.17691 0 2.8571 0.319811 2.8571 0.714295C2.8571 1.10878 3.17691 1.42859 3.5714 1.42859H7.56125L0.209219 8.78067C-0.0697397 9.05963 -0.0697397 9.51184 0.209219 9.7908C0.348652 9.93027 0.531454 10 0.714255 10C0.897056 10 1.07989 9.9303 1.21935 9.79077L8.57141 2.43872V6.42859C8.57141 6.82307 8.89122 7.14288 9.28571 7.14288C9.68019 7.14288 10 6.82307 10 6.42859V0.714295C9.99997 0.319811 9.68016 0 9.28568 0Z'
                            fill='#9CD3EE'
                          />
                        </svg>
                      </div>
                    </div>
                  </a>
                  <div className={'hidden phablet:block laptop:col-span-1 '} />
                </React.Fragment>
              )
            })}
        </div>
        <div className={'hidden phablet:block tablet:col-span-1 laptop:col-span-1 '} />
        <div className={'hidden phablet:block laptop:col-span-1 '} />

        <div className={(!blok.job_items ? 'btn-hidden' : '') + ' container grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8 pt-16'}>
          <div className={'hidden phablet:block laptop:col-span-1 '} />
          <div ref={buttonRef}>
            <Button hasMargin={false} link={blok.homerun_link} text={blok.homerun_link_text || 'View all careers'} isPrimary={false} isExternalLink={true} />
          </div>
        </div>

        {/* End Carousel */}
        <div className={'hidden phablet:block phablet:col-span-1 laptop:col-span-2'} />
      </div>
    </SbEditable>
  )
}

export default CareersManual
