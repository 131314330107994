import React from "react";
import SbEditable from "storyblok-react";
// import DynamicComponent from '../DynamicComponent'

import { Title } from "../common/Titles";
import { getPalette } from "../../services/getPalette";
import { AnimateInRichText } from "../common/Animations";
import { MaskedImage } from "../common/MaskedImage";
// import { BackgroundDots } from '../common/Dots/BackgroundDots'

const ImageGrid = ({ blok, imageBefore }) => {
    // const body = blok &&
    // blok.body &&
    // blok.body.map((childBlok) => <DynamicComponent blok={childBlok} key={childBlok._uid} />)
    const key = typeof blok._editable !== "undefined" ? blok._uid + "-edit" : blok._uid;
    const palette = getPalette(blok.palette);

    React.useEffect(() => {}, []);

    return (
        <SbEditable content={blok} key={key}>
            <div data-scroll-section='true' className={"relative -mt-1 bg-" + palette.background + " text-" + palette.text_main}>
                {/* {blok.show_dot_background && <BackgroundDots />} */}

                <div
                    className={
                        "container grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8 " +
                        (imageBefore ? "pt-40 phablet:pt-52 laptop:pt-72 large:pt-96" : "pt-36 phablet:pt-48  ")
                    }>
                    {/* {/* Uses anchor link text to create an anchor link */}
                    {blok.anchor_link && <a id={blok.anchor_link} className='element' />}

                    <Title
                        uniqueID={key}
                        palette={palette}
                        useSecondary={blok.use_secondary_color}
                        smallTitle={blok.small_title}
                        text={blok.headline}
                    />
                    <div className={"col-span-1 phablet:col-span-6 laptop:col-span-6 rich-text-block"} />
                    <div className={"col-span-5 phablet:col-span-6 laptop:col-span-5 rich-text-block"}>
                        <div className={"w-full text-" + palette.text_main}>
                            <AnimateInRichText uniqueID={key} text={blok.intro_text} />
                        </div>
                    </div>

                    <div className={"hidden laptop:block laptop:col-span-1 "} />
                    <div className={"hidden laptop:block laptop:col-span-1 "} />

                    <div className='h-fit auto-rows-fit col-span-6 phablet:col-span-12  phablet:grid-cols-12 laptop:col-span-12 laptop:h-px-410 grid grid-cols-6  gap-x-2 laptop:gap-x-4 mt-28 pb-28 laptop:mt-20 large:gap-x-8'>
                        <div className={"hidden phablet:block phablet:col-span-1 "} />

                        {blok &&
                            blok.image.map((image, index) => {
                                return [
                                    <div
                                        key={index + "block0"}
                                        className={
                                            " text-center h-52 col-span-4 phablet:col-span-3 laptop:col-span-2 col-start-2 phablet:col-start-auto"
                                        }>
                                        <MaskedImage
                                            uniqueID={index + "-grid-image-" + key}
                                            image={image.image}
                                            maxWidth={2400}
                                            maxHeight={1000}
                                            offsetBottom={-10}
                                            offsetTop={85}
                                            objectFit={"contain"}
                                            zoom={false}
                                        />
                                    </div>,
                                    <div key={index + "block1"} className={"hidden phablet:block phablet:col-span-2 laptop:col-span-1"} />,
                                    <div key={index + "block2"} className={"hidden phablet:block phablet:col-span-1"} />,
                                ];
                            })}
                    </div>

                    {/* End Carousel */}
                    <div className={"hidden laptop:block laptop:col-span-1 "} />
                    <div className={"hidden laptop:block laptop:col-span-1 "} />
                </div>
            </div>
        </SbEditable>
    );
};

export default ImageGrid;
