import React from "react";
import SbEditable from "storyblok-react";

import { Title } from "../common/Titles";
import { getPalette } from "../../services/getPalette";
import { AnimateInRichText } from "../common/Animations";
import { MaskedImage } from "../common/MaskedImage";

const Section = ({ blok, imageBefore }) => {
    const key = typeof blok._editable !== "undefined" ? blok._uid + "-edit" : blok._uid;
    const palette = getPalette(blok.palette);

    return (
        <SbEditable content={blok} key={key}>
            <div data-scroll-section='true' className={"relative -mt-1 bg-" + palette.background + " text-" + palette.text_main}>
                <div
                    className={
                        "container grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8 " +
                        (imageBefore ? "pt-40 phablet:pt-52 laptop:pt-72 large:pt-96 pb-48" : "pt-36 phablet:pt-48  pb-48")
                    }>
                    {/* Uses anchor link text to create an anchor link */}
                    {blok.anchor_link && <a id={blok.anchor_link} className='element' />}

                    <Title
                        uniqueID={key}
                        palette={palette}
                        useSecondary={blok.use_secondary_color}
                        smallTitle={blok.small_title}
                        text={blok.headline}
                        halfWidth={false}
                    />
                    <div className={"col-span-1 phablet:col-span-1 laptop:col-span-1 rich-text-block"} />
                    <div className={"col-span-5 phablet:col-span-6 laptop:col-span-8 rich-text-block mt-20"}>
                        <div className={"w-full text-" + palette.text_main}>
                            <AnimateInRichText uniqueID={key} text={blok.intro_text} />
                        </div>
                    </div>
                    <div className={"hidden laptop:block laptop:col-span-1 "} />

                    <div className={"col-start-2 col-span-10"}>
                        <MaskedImage uniqueID={key} image={blok.image} />
                    </div>
                </div>
            </div>
        </SbEditable>
    );
};

export default Section;
