// import DynamicComponent from '../DynamicComponent'
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-storyblok-image";
import gsap from "gsap";
import React, { useState, useRef, useEffect } from "react";
import SbEditable from "storyblok-react";
import { useLayout } from "../../hooks/useLayout";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { getPalette } from "../../services/getPalette";
import { AnimateInRichText, SlideInText } from "../common/Animations";
import { Title } from "../common/Titles";
import { useInView } from "react-intersection-observer";

let CURRENT_INDEX = 0;

const TabCarousel = ({ blok, imageBefore }) => {
    const [currentStep, setCurrentStep] = useState(0);

    const [tabTitles, setTabTitles] = useState(null);
    const refTitles = useRef(null);
    const refTextBlocks = useRef(null);
    const refImages = useRef(null);

    const { ref, inView } = useInView({ triggerOnce: true, rootMargin: "0px 0px -30% 0px" });

    // const body = blok &&
    // blok.body &&
    // blok.body.map((childBlok) => <DynamicComponent blok={childBlok} key={childBlok._uid} />)
    const key = typeof blok._editable !== "undefined" ? blok._uid + "-edit" : blok._uid;
    const palette = getPalette(blok.palette);
    const [hideCategories, setHideCategories] = React.useState(true);
    const sliderID =
        "carousel_" +
        blok.headline
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/ /g, "")
            .toString(36)
            .substr(2, 9);
    const titleID =
        "carousel_title_" +
        blok.headline
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/ /g, "")
            .toString(36)
            .substr(2, 9);

    const sliderRef = React.useRef(blok.tab_content.map(() => React.createRef()));
    const tabsRef = React.useRef(blok.tab_content.map(() => React.createRef()));
    const itemsRef = React.useRef(blok.tab_content.map(() => React.createRef()));
    // const yellowLineRef = React.useRef(() => React.createRef());
    const tabsTitleRef = React.useRef(() => React.createRef());

    const [clicked, setClicked] = React.useState(false);
    const [sliderInView, setSliderInView] = React.useState(false);
    const [startAnimationOnce, setStartAnimationOnce] = React.useState(false);

    let currentSlider = sliderRef.current[0];
    let currentTab = tabsRef.current[0];
    let currentItem = itemsRef.current[0];
    const selectLineWidth = 25;
    let newIndex = 1;

    //ANIMATION
    const requestRef = React.useRef();
    const previousTimeRef = React.useRef();
    // let counter = 0;

    const sectionRef = React.useRef();
    const sliderWrapperRef = React.useRef();
    const sliderContentRef = React.useRef();
    const { windowWidth } = useWindowDimensions();
    const [tl_intro] = React.useState(gsap.timeline({ paused: true }));
    const [tl_intro2] = React.useState(gsap.timeline({ paused: true }));
    const [tl_tabtimer] = React.useState(gsap.timeline({ paused: true, overwrite: true }));

    let tlMain = null;

    const refParent = useRef(null);

    const tabsOnClick = (e, index) => {
        const currentTextBlock = refTextBlocks.current ? refTextBlocks.current.children[CURRENT_INDEX] : null;

        if (e) {
            const currentTitle = tabTitles[CURRENT_INDEX];
            const tlTab = gsap.timeline();
            tlTab.to(currentTitle, { xPercent: 101, duration: 0.3, ease: "Power3.easeInOut" });
            const newTitle = tabTitles[index];
            tlTab.to(newTitle, { xPercent: 0, duration: 0.3, ease: "Power3.easeInOut" }, ">");
            if (currentTextBlock) {
                tlTab.to(currentTextBlock, { y: -30, duration: 0.5, autoAlpha: 0, ease: "Quad.easeInOut" });
            }
        }

        if (tlMain !== null) {
            tlMain.kill();
            tlMain = null;
        }

        tlMain = gsap.timeline();
        tlMain.to(currentTextBlock, { y: -30, duration: 0.5, autoAlpha: 0, ease: "Quad.easeInOut" });

        const nextTextBlock = refTextBlocks.current.children[index];
        const titles = nextTextBlock.querySelectorAll(".tab-carousel-title");
        const descriptions = nextTextBlock.querySelectorAll(".tab-carousel-description");
        tlMain.set(nextTextBlock, { y: 0, autoAlpha: 1 });

        tlMain.fromTo(titles, { yPercent: 101 }, { yPercent: 0, stagger: 0.1 }, "<");
        tlMain.fromTo(descriptions, { yPercent: 101 }, { yPercent: 0, stagger: 0.1 }, "<0.1");

        for (const slide of sliderRef.current) {
            if (slide.classList.contains("tabs-current")) {
                slide.className = "anim tabs-previous";
                setTimeout(() => {
                    slide.className = "anim tabs-hidden";
                }, 1000);
            } else {
                slide.className = "anim tabs-hidden";
            }
        }

        const newSlider = sliderRef.current[index];
        newSlider.className = "anim tabs-current";

        if (newSlider.offsetParent.classList.contains("content-bottom_left")) {
            newSlider.offsetParent.className = "content-top_right w-full h-72 laptop:h-px-410";
        } else {
            newSlider.offsetParent.className = "content-bottom_left w-full h-72 laptop:h-px-410";
        }

        // const currentImage = refImages.current.children[CURRENT_INDEX];
        // tlMain.set(currentImage, { duration: 0.5, autoAlpha: 0 }, "<0.4");
        // const nextImage = refImages.current.children[index];
        // tlMain.to(nextImage, { duration: 0.2, autoAlpha: 1 }, "<0.5");

        tlMain = null;

        setCurrentStep(index);
        // currentStep = index;

        updateTitles(index);

        // updateTitles(currentStep, index);
        // updateDescription();

        // setCurrentStep(index);
        // CURRENT_STEP = index;

        // Items
        // To avoid bugs when clicking on an element already in current
        // if (!sliderRef.current[index].classList.contains("tabs-current")) {
        // for (const item of itemsRef.current) {
        //     item.className =
        //         "text grid grid-cols-1 gap-7 absolute top-0 w-full phablet:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-2 large:grid-cols-2 item_hide";
        // }

        // currentItem = itemsRef.current[index];

        // counter = 0;
        // if (index >= 2) {
        // tabsTitleRef.current.scrollLeft = 500;
        // tabsTitleRef.current.scroll({ left: 1000, behavior: "smooth" });
        // } else {
        // tabsTitleRef.current.scrollLeft = 0;
        // tabsTitleRef.current.scroll({ left: 0, behavior: "smooth" });
        // }

        // setTimeout(() => {
        //     setHideCategories(true);
        //     setTimeout(() => {
        //         currentItem.className =
        //             "text grid grid-cols-1 gap-7 absolute top-0 w-full phablet:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-2 large:grid-cols-2 item_current";
        //         if (windowWidth < 480) {
        //             currentItem.offsetParent.style.height = currentItem.clientHeight + "px";
        //         }
        //         setHideCategories(false);
        //         if (locoScroll) locoScroll.update();
        //     }, 200);
        // }, 300);
        // }

        // for (const tab of tabsRef.current) {
        //     tab.children[0].className = "titleText";
        //     tab.children[1].className = "titleLine";
        // }

        // const newtab = tabsRef.current[index];
        // newtab.children[0].className = "titleText selectedTitle";
        // newtab.children[1].className = "titleLine selectedLine";
        // currentTab = newtab;

        // // // Images
        // // To avoid bugs when clicking on an element already in current
        // if (!sliderRef.current[index].classList.contains("tabs-current")) {
        //     for (const slide of sliderRef.current) {
        //         if (slide.classList.contains("tabs-current")) {
        //             slide.className = "anim tabs-previous";
        //             setTimeout(() => {
        //                 slide.className = "anim tabs-hidden";
        //             }, 1000);
        //         } else {
        //             slide.className = "anim tabs-hidden";
        //         }
        //     }
        //     const newSlider = sliderRef.current[index];
        //     newSlider.className = "anim tabs-current";

        //     if (newSlider.offsetParent.classList.contains("content-bottom_left")) {
        //         newSlider.offsetParent.className = "content-top_right w-full h-72 laptop:h-px-410";
        //     } else {
        //         newSlider.offsetParent.className = "content-bottom_left w-full h-72 laptop:h-px-410";
        //     }
        // }
    };

    useEffect(() => {
        CURRENT_INDEX = currentStep;
    }, [currentStep]);

    // React.useEffect(() => {

    // }, [currentStep, tabTitles]);

    const updateTitles = (index) => {
        // const elements = gsap.utils.toArray(".titleLine");
        const activeTitle = tabTitles[index];

        // const activeTitle = refTitles.current.children[currentStep];

        tl_tabtimer.fromTo(
            activeTitle,
            {
                xPercent: -100,
            },
            {
                xPercent: 0,
                ease: "linear",
                duration: 4,
                onComplete: () => {
                    gsap.to(activeTitle, { xPercent: 101, duration: 0.3, ease: "Power3.easeInOut" });

                    let newStep = index;
                    newStep += 1;

                    if (newStep > 3) {
                        newStep = 0;
                    }

                    tabsOnClick(null, newStep);
                },
            }
        );
    };

    // const updateDescription = () => {
    //     const currentTextBlock = refTextBlocks.current.children[CURRENT_STEP];
    //     const titles = gsap.utils.toArray(".tab-carousel-title");
    //     const tl = gsap.timeline();
    //     tl.to(currentTextBlock, { autoAlpha: 1 });
    // };

    useEffect(() => {
        if (tabTitles !== null) {
            updateTitles(0);
        }
    }, [tabTitles]);

    useEffect(() => {
        const titles = gsap.utils.toArray(".titleLine");
        gsap.set(titles, { xPercent: -101 });
        setTabTitles(titles);

        currentSlider = sliderRef.current[0];
        currentTab = tabsRef.current[0];
        currentItem = itemsRef.current[0];

        // Set the height of the div 'description'
        itemsRef.current[0].offsetParent.style.height = itemsRef.current[0].clientHeight + "px";

        //Set intro animation
        tl_intro.fromTo(
            refImages.current.parentNode,
            {
                clipPath: "polygon(0% 200%, 0% 100%, 33% 100%, 33% 200%, 33% 150%, 66% 150%, 66% 200%, 66% 200%, 100% 200%, 100% 200%, 0% 200%)",
            },
            {
                clipPath: "polygon(0% 200%, 0% 0%, 33% 0%, 33% 200%, 33% 0%, 66% 0%, 66% 200%, 66% 0%, 200% 0%, 100% 200%, 0% 200%)",
                duration: 1,
                delay: 0.3,
            },
            0
        );

        tl_intro.from(
            tabsTitleRef.current,
            {
                opacity: 0,
                duration: 0.5,
            },
            "<0.2"
        );

        tl_intro.from(
            refTextBlocks.current,
            {
                opacity: 0,
                duration: 0.5,
            },
            "<0.2"
        );
    }, []);

    // requestRef.current = requestAnimationFrame(animate);
    // return () => cancelAnimationFrame(requestRef.current);
    // React.useEffect(() => {
    //     if (locoScroll) {
    //         locoScroll.on("call", (func, direction) => {
    //             if (func === sliderID) {
    //                 if (tl_intro) {
    //                     if (direction === "enter") {
    //                         tl_intro.play();
    //                         tl_tabtimer.play();
    //                     }
    //                 }
    //             }
    //             if (func === titleID) {
    //                 if (tl_intro2) {
    //                     if (direction === "enter") {
    //                         //TEXTS IN VIEW
    //                         setHideCategories(false);
    //                         tl_intro2.play();
    //                     }
    //                 }
    //             }
    //         });

    //         locoScroll.on("scroll", (args) => {
    //             // Get all current elements : args.currentElements
    //             if (typeof args.currentElements[sliderID] === "object" || typeof args.currentElements[titleID] === "object") {
    //                 setSliderInView(true);
    //             } else {
    //                 setSliderInView(false);
    //             }
    //         });
    //     }
    // }, [locoScroll]);

    // Allows you to start the animation the first time the component appears
    // useEffect(() => {
    //     // if (sliderInView == false) {
    //     //     tabsTitleRef.current.classList.add("paused");
    //     // } else {
    //     //     tabsTitleRef.current.classList.remove("paused");
    //     // }
    //     // When the animation is activated the first time, this function will not be reactivated. This avoids some bugs
    //     if (startAnimationOnce == false) {
    //         if (sliderInView) {
    //             setStartAnimationOnce(true);
    //             // tabTimer();
    //         } else {
    //             // Nothing
    //         }
    //     }
    // }, [sliderInView]);

    useEffect(() => {
        if (inView) {
            tl_intro.play();
            tl_tabtimer.play();
        }
    }, [inView, tl_intro]);

    return (
        <SbEditable content={blok} key={key}>
            <div data-scroll-section='true' className={"relative -mt-1 bg-" + palette.background + " text-" + palette.text_main} ref={ref}>
                <div
                    className={
                        "container grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8 " +
                        (imageBefore ? "pt-40 phablet:pt-52 laptop:pt-72 large:pt-96 pb-60" : "pt-20 pb-60")
                    }
                    ref={refParent}>
                    {/* Uses anchor link text to create an anchor link */}
                    {blok.anchor_link && <a id={blok.anchor_link} className='element' />}

                    <Title
                        uniqueID={key}
                        palette={palette}
                        useSecondary={blok.use_secondary_color}
                        smallTitle={blok.small_title}
                        text={blok.headline}
                        refParent={refParent}
                    />
                    <div className={"col-span-1 phablet:col-span-6 laptop:col-span-6"} />
                    <div className={"col-span-5 phablet:col-span-6 laptop:col-span-5 rich-text-block"}>
                        <div className={"w-full text-" + palette.text_main}>
                            <AnimateInRichText uniqueID={key} text={blok.intro_text} />
                        </div>
                    </div>
                    <div className={"hidden laptop:block laptop:col-span-1 "} />

                    <div className={"hidden phablet:block phablet:col-span-1 laptop:col-span-2"} />

                    <div ref={sliderWrapperRef} className={"mt-32 col-span-6 phablet:col-span-10 laptop:col-span-8"}>
                        <div className='relative overflow-hidden'>
                            <div className='content-top_right w-full h-72 laptop:h-px-410' ref={refImages}>
                                {blok &&
                                    blok.tab_content.map((image, index) => {
                                        let fluidImageProps = null;
                                        if (image.image.filename) {
                                            fluidImageProps = getFluidGatsbyImage(image.image.filename, {
                                                maxWidth: 3000,
                                                maxHeight: 2000,
                                                format: "webp",
                                                toFormat: "webp",
                                                quality: "80",
                                            });
                                        }

                                        return [
                                            <div key={index} ref={(el) => (sliderRef.current[index] = el)} className='carousel-image'>
                                                {fluidImageProps && (
                                                    <Img objectFit='cover' objectPosition='50% 50%' fluid={fluidImageProps} className='img' />
                                                )}
                                            </div>,
                                        ];
                                    })}
                            </div>
                        </div>
                        <div
                            className={(clicked ? "title paused" : "title") + (sliderInView ? " in-view" : " not-in-view")}
                            ref={(el) => (tabsTitleRef.current = el)}>
                            <div className='titleContent gap-4 mobile:gap-0 justify-content' ref={refTitles}>
                                {blok &&
                                    blok.tab_content.map((tab, index) => {
                                        return [
                                            <div
                                                key={index}
                                                className='text mask'
                                                onClick={(e) => {
                                                    if (index === currentStep) return null;
                                                    setClicked(true);
                                                    tl_tabtimer.kill();
                                                    tabsOnClick(e, index);
                                                }}
                                                ref={(el) => (tabsRef.current[index] = el)}>
                                                <p className={index == currentStep ? "titleText selectedTitle" : "titleText"}>{tab.title}</p>
                                                <div className={"titleLine"} />
                                            </div>,
                                        ];
                                    })}
                            </div>
                            <div className='bottomLine'></div>
                        </div>
                        <div className='descriptions h-96 relative' ref={refTextBlocks}>
                            {blok &&
                                blok.tab_content.map((items, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                "text grid grid-cols-1 gap-7 absolute top-0 w-full phablet:grid-cols-1 tablet:grid-cols-1 laptop:grid-cols-2 large:grid-cols-2 tab-carousel-block"
                                            }
                                            ref={(el) => (itemsRef.current[index] = el)}>
                                            {items.content.map((contentItem, subindex) => {
                                                // const delay = 150 + subindex * 150;
                                                return (
                                                  <div className='element' key={contentItem.title + '-item'}>
                                                    <div className='text-liminal-yellow mb-2 text-xl'>
                                                      <div className={'slide-text-container'}>
                                                        <div className={'slide-text tab-carousel-title'}>{contentItem.title}</div>
                                                      </div>

                                                      {/* <SlideInText
                                                                text={contentItem.title}
                                                                delayClass={"delay-" + delay}
                                                                visible={hideCategories ? true : false}
                                                                fast={false}
                                                                hideInstant={true}
                                                            /> */}
                                                    </div>
                                                    <div>
                                                      <div className={'slide-text-container '}>
                                                        <div className={'slide-text tab-carousel-description'}>{contentItem.description}</div>
                                                      </div>
                                                      {/* <SlideInText
                                                                text={contentItem.description}
                                                                delayClass={"delay-" + delay}
                                                                visible={hideCategories ? true : false}
                                                                fast={false}
                                                                hideInstant={true}
                                                            /> */}
                                                    </div>
                                                  </div>
                                                )
                                            })}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    {/* End Carousel */}
                    <div className={"hidden phablet:block phablet:col-span-1 laptop:col-span-2"} />
                </div>
            </div>
        </SbEditable>
    );
};

export default TabCarousel;
