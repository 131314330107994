import React, { useRef } from "react";
import SbEditable from 'storyblok-react'
import { getPalette } from '../../services/getPalette'
import { AnimateInRichText } from '../common/Animations'
import { MaskedImage } from '../common/MaskedImage'
import MaskedMap from '../common/MaskedMap'
import { Title } from '../common/Titles'

const ImageMapText = ({ blok, imageBefore }) => {
  const key = typeof blok._editable !== 'undefined' ? blok._uid + '-edit' : blok._uid
  const palette = getPalette(blok.palette)
  const refParent = useRef(null)
  return (
    <SbEditable content={blok} key={key}>
      <div
        data-scroll-section='true'
        className={'relative -mt-1 bg-' + palette.background + ' text-' + palette.text_main}
      >
        <div
          className={
            'container relative grid grid-cols-6 auto-rows-max	 phablet:grid-cols-12  items-start  gap-x-2 laptop:gap-x-4 large:gap-x-8 ' +
            (imageBefore ? 'pt-40 phablet:pt-52 laptop:pt-72 large:pt-96' : 'pt-36 phablet:pt-48 ') +
            ' ' +
            (blok.image_below ? 'pb-40 phablet:pb-52 tablet:pb-60 laptop:pb-72 large:pb-96' : 'pb-48')
          }
        >
          <div
            className={
              'col-span-6 phablet:col-span-6 laptop:col-span-5  grid grid-cols-6 phablet:grid-cols-6   gap-x-2 laptop:gap-x-4 large:gap-x-8 '
            }
            ref={refParent}
          >
            {/* Uses anchor link text to create an anchor link */}
            {blok.anchor_link && <a id={blok.anchor_link} className='element' />}
            <Title
              uniqueID={key}
              palette={palette}
              useSecondary={blok.use_secondary_color}
              text={blok.headline}
              smallTitle={true}
              halfWidth={true}
              refParent={refParent}
            />
            <div className={'col-span-6 phablet:col-span-6 laptop:col-span-6 mb-16'} />

            <div className={'hidden phablet:block phablet:col-span-2 laptop:col-span-1 rich-text-block'} />
            <div
              className={'col-span-6 phablet:col-span-6 laptop:col-span-5 rich-text-block mb-10 phablet:mb-0'}
            >
              <AnimateInRichText uniqueID={key} text={blok.col1_text} />
            </div>

            <div className={'hidden phablet:block phablet:col-span-4 laptop:col-span-4 rich-text-block'} />
            <div
              className={
                'col-span-6 phablet:col-span-2 laptop:col-span-2 rich-text-block mb-10 phablet:-mb-4'
              }
            >
              <AnimateInRichText uniqueID={key} text={blok.col2_text} />
            </div>
          </div>
          <div
            className={
              'col-span-6 phablet:col-span-6 laptop:col-span-5 rich-text-block self-stretch  grid grid-cols-6 phablet:grid-cols-6  relative  gap-x-2 laptop:gap-x-4 large:gap-x-8'
            }
          >
            <div className={'col-span-6  h-80 phablet:h-full'}>
              {blok.use_map ? (
                <MaskedMap
                  uniqueID={key}
                  revealWithScroll={false}
                  latitude={blok.map_lat || 52.367766}
                  longitude={blok.map_long || 4.896874}
                  url={blok.map_link}
                />
              ) : (
                <MaskedImage uniqueID={key} image={blok.image} objectFit='cover' fullHeight={true} />
              )}
            </div>
            <div className={'absolute w-1/3  left-0 top-0 h-1/2 bg-' + palette.background + ' '} />
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default ImageMapText;
