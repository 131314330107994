import Vimeo from "@u-wave/react-vimeo";
import gsap from "gsap";
import React from "react";
import SbEditable from "storyblok-react";
import { render } from "storyblok-rich-text-react-renderer";
import { useBGVideoLoaded } from "../../hooks/useBGVideoLoaded";
import { useLayout } from "../../hooks/useLayout";
import { AnimateInSplitLines } from "../common/Animations";
// import { getPalette } from '../../services/getPalette'
import Button from "../common/Button";
import { HeroTitle } from "../common/Titles";

const Hero = ({ blok }) => {
    const key = typeof blok._editable !== "undefined" ? blok._uid + "-edit" : blok._uid;
    const refBackground = React.useRef(null);
    const refBackgroundCover = React.useRef(null);
    const refDownArrow = React.useRef(null);
    const buttonRef = React.useRef();
    const vimeoRef = React.useRef();
    const videoRef = React.useRef();

    const { isPageLoaded } = useLayout();
    const setBGVideoLoaded = useBGVideoLoaded();
    const vimeoURL = blok.background_embed.vimeo_raw;
    const introDelay = 500;

    const elementID = key + "-hero";
    const [tl] = React.useState(gsap.timeline({}));
    const [tl2] = React.useState(gsap.timeline({}));
    const [tl3] = React.useState(gsap.timeline({}));

    const renderedIntroText = typeof text === "string" ? blok.intro_text : render(blok.intro_text);

    React.useEffect(() => {
        if (isPageLoaded) {
            tl.fromTo(
                refBackground.current,
                {
                    scaleY: 1.5,
                    scaleX: 1.5,
                },
                {
                    scaleY: 1.3,
                    scaleX: 1.3,
                    y: 25,
                    duration: 1.5,
                    ease: "Power1.easeInOut",
                }
            );
            tl.from(buttonRef.current, {
                yPercent: 100,
                autoAlpha: 0,
                duration: 0.5,
                ease: "Power3.easeOut",
            });
            tl.from(refDownArrow.current, {
                yPercent: 100,
                autoAlpha: 0,
                duration: 0.7,
                ease: "Power3.easeOut",
            });

            tl3.clear().fromTo(
                refBackgroundCover.current,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 0.8,
                    ease: "Power1.ease",
                    scrollTrigger: {
                        trigger: refBackground.current,
                        scrub: true,
                        start: "10%",
                    },
                }
            );
            tl2.clear().fromTo(
                refDownArrow.current,
                { opacity: 1 },
                {
                    opacity: 0,
                    duration: 0.8,
                    ease: "Power1.ease",
                    scrollTrigger: {
                        trigger: refBackground.current,
                        scrub: true,
                        start: "20%",
                        end: "50%",
                    },
                }
            );
        }
    }, [isPageLoaded]);

    React.useEffect(() => {
        if (vimeoURL.includes("https://vimeo.com/")) {
            vimeoURL.replace("https://vimeo.com/", "https://player.vimeo.com/video/");
        }

        if (blok.background_video.filename && !vimeoURL.includes("https://player.vimeo.com/")) setBGVideoLoaded(true);

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // refBackground.current.style.height = screen.height + 60 + "px !important";
        }
    }, []);

    return (
        <SbEditable content={blok} key={key}>
            {blok.anchor_link && <a id={blok.anchor_link} className='element' />}

            <div ref={refBackground} className={"fixed left-0 top-0 w-full h-full webkit-translate-zero video-background opacity-100"}>
                {vimeoURL.includes("https://player.vimeo.com/") ? (
                    <Vimeo
                        className='object-cover w-full h-full'
                        ref={vimeoRef}
                        video={vimeoURL}
                        background
                        autoplay
                        loop
                        muted={true}
                        showByline={false}
                        showTitle={false}
                        // onLoaded={() => setTimeout(() => {setBGVideoLoaded(true)},6000)}
                        onLoaded={(data) => setBGVideoLoaded(true)}
                        // onReady={(player) => console.log(player)}
                    />
                ) : // ? <iframe className="object-cover w-screen h-full" src={vimeoURL + '?background=1&autoplay=1&loop=1&byline=0&title=0'} frameBorder="0" ref={vimeoRef}/>
                blok.background_video.filename ? (
                    <video
                        ref={videoRef}
                        poster={blok.background_image ? blok.background_image.filename : ""}
                        onLoadedData={() => setBGVideoLoaded(true)}
                        className='opacity-70 object-cover w-full h-full'
                        playsInline
                        autoPlay
                        muted
                        loop
                        id='hero'>
                        <source src={blok.background_video.filename} type='video/mp4' />
                    </video>
                ) : (
                    <img
                        className=' object-cover w-full h-full '
                        alt='background-image'
                        src={blok.background_image ? blok.background_image.filename : ""}
                        id={blok._uid}
                    />
                )}
            </div>
            <div ref={refBackgroundCover} className={"fixed inset-0 video-background-cover bg-liminal-blue opacity-0"} />

            <div className='-mt-1 w-full min-h-full	webkit-translate-zero overflow-hidden relative'>
                <div className=' w-full min-h-full webkit-translate-zero relative'>
                    <div className='w-full set-full-screen flex justify-center items-center relative'>
                        <div className='container pt-24 pb-16 grid grid-cols-6 phablet:grid-cols-12 phablet:pt-18 phablet:pb-18  gap-x-2 laptop:gap-x-4 large:gap-x-8   '>
                            <div className='hidden phablet:col-span-1 phablet:block' />
                            <HeroTitle text={blok.headline} animateIn={true} start={isPageLoaded} delay={introDelay} />
                            <div className='hidden phablet:col-span-1 phablet:block' />
                            {blok.intro_text && (
                                <>
                                    <div className='col-span-1 down-arrow' ref={refDownArrow} />
                                    <div
                                        className={
                                            " col-span-5 col-start-2  phablet:col-span-7 phablet:col-start-6 laptop:col-start-7  laptop:col-span-5 large:col-start-7  large:col-span-4 rich-text-block"
                                        }>
                                        <div className={"w-full text-liminal-white"}>
                                            <AnimateInSplitLines
                                                text={renderedIntroText}
                                                start={isPageLoaded}
                                                delay={introDelay + 200}
                                                useScroll={false}
                                            />
                                        </div>
                                        <div className='overflow-hidden'>
                                            <div ref={buttonRef}>
                                                <Button hasMargin={true} link={blok.button_link} text={blok.button_text} isPrimary={true} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className='hidden phablet:col-span-1 phablet:block large:col-span-2' />
                        </div>
                    </div>
                </div>
            </div>
        </SbEditable>
    );
};

export default Hero;
