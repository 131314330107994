import React from "react";
import gsap from "gsap";

import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { MaskedImage } from "./MaskedImage";

export const FloatingImage = ({ uniqueID, floatingItem, palette, position }) => {
    const [tl] = React.useState(gsap.timeline({ paused: true }));
    const refImage = React.useRef(null);
    const { windowWidth } = useWindowDimensions();

    React.useEffect(() => {
        if (windowWidth > 480) {
            const moveY = position === "left" ? "-10" : "-10";

            tl.fromTo(
                refImage.current,
                {
                    yPercent: -moveY,
                },
                {
                    yPercent: moveY,
                    duration: 2,
                }
            );
        }
    }, []);

    return (
        <div
            className={floatingItem.show_cutout ? (floatingItem.cutout_right ? "image-mask-bottom-right" : "image-mask-top-left") : ""}
            ref={refImage}>
            <MaskedImage uniqueID={uniqueID} image={floatingItem.image} maxHeight={1500} maxWidth={2000} moveVertical={true} />
        </div>
    );
};
