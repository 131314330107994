import React, { useRef } from "react";
import SbEditable from "storyblok-react";

import { Title } from "../common/Titles";
import { getPalette } from "../../services/getPalette";
import { AnimateInRichText } from "../common/Animations";
import { MaskedImage } from "../common/MaskedImage";

const ImageText = ({ blok, imageBefore }) => {
    const key = typeof blok._editable !== "undefined" ? blok._uid + "-edit" : blok._uid;
    const palette = getPalette(blok.palette);
    const refParent = useRef(null);

    return (
        <SbEditable content={blok} key={key}>
            <div data-scroll-section='true' className={"relative -mt-1 z-10 bg-" + palette.background + " text-" + palette.text_main} ref={refParent}>
                <div
                    className={
                        "container relative grid grid-cols-6 phablet:grid-cols-12  items-start  gap-x-2 laptop:gap-x-4 large:gap-x-8 " +
                        (imageBefore ? "pt-40 phablet:pt-52 laptop:pt-72 large:pt-96" : "pt-36 phablet:pt-48 ") +
                        " " +
                        (blok.image_below ? "pb-40 phablet:pb-52 tablet:pb-60 laptop:pb-72 large:pb-96" : "pb-48")
                    }>
                    {/* Uses anchor link text to create an anchor link */}
                    {blok.anchor_link && <a id={blok.anchor_link} className='element' />}

                    <Title
                        uniqueID={key}
                        refParent={refParent}
                        palette={palette}
                        useSecondary={blok.use_secondary_color}
                        text={blok.headline}
                        smallTitle={true}
                        halfWidth={true}
                    />

                    <div className={"col-span-6 phablet:col-span-6 laptop:col-span-5 rich-text-block"}>
                        <div className={"w-full text-" + palette.text_main}>
                            <AnimateInRichText uniqueID={key} text={blok.intro_text} />
                        </div>
                    </div>
                    <div className={"hidden laptop:block laptop:col-span-1 "} />

                    {blok.image_below && (
                        <div
                            className={
                                "container absolute bottom-0 translate-y-50p grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8 image-divider-container"
                            }>
                            <div className={"col-span-0 phablet:col-span-1 laptop:col-span-2 rich-text-block"} />
                            <div
                                className={"col-span-6 phablet:col-span-10 laptop:col-span-8 rich-text-block"}
                                data-scroll-direction={"vertical"}
                                data-scroll
                                data-scroll-speed={1.5}>
                                {/* <Img objectFit="cover" objectPosition="50% 50%" fluid={fluidImageProps} /> */}
                                <MaskedImage uniqueID={key} image={blok.image} />
                            </div>
                            <div className={"col-span-0 phablet:col-span-1 laptop:col-span-2 rich-text-block"} />
                        </div>
                    )}
                </div>
            </div>
        </SbEditable>
    );
};

export default ImageText;
