import { navigate } from "gatsby-link";
import React, { useRef, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import SbEditable from 'storyblok-react'
import { getPalette } from '../../services/getPalette'
import { AnimateInRichText } from '../common/Animations'
import Button from '../common/Button'
// import DynamicComponent from '../DynamicComponent'
import { Title } from '../common/Titles'

const FormContactNetlify = ({ blok, imageBefore }) => {
  // const body = blok &&
  // blok.body &&
  // blok.body.map((childBlok) => <DynamicComponent blok={childBlok} key={childBlok._uid} />)

  const key = typeof blok._editable !== 'undefined' ? blok._uid + '-edit' : blok._uid
  const palette = getPalette(blok.palette)
  const formRef = React.useRef(() => React.createRef())
  const [submitted, setSubmitted] = React.useState(false)
  const [test, setTest] = React.useState(false)
  const [formClicked, setFormClicked] = React.useState(false)
  const [phoneValue, setPhoneValue] = useState()

  const refParent = useRef(null)

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handlePhoneChange = (e) => {
    setState({ ...state, ['phoneNumber']: phoneValue })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)

    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  function scrollToFormSubmitted() {
    formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  React.useEffect(() => {}, [])

  return (
    <SbEditable content={blok} key={key}>
      <div
        data-scroll-section='true'
        className={'form-container relative  -mt-1  bg-' + palette.background + ' text-' + palette.text_main}
      >
        <div
          ref={refParent}
          className={
            'container relative grid grid-cols-6  phablet:grid-cols-12 gap-x-2 laptop:gap-x-4 large:gap-x-8 ' +
            (imageBefore ? 'pt-40 phablet:pt-52 laptop:pt-72 large:pt-96' : 'pt-36 phablet:pt-48 ') +
            ' ' +
            (blok.image_below ? 'pb-40 phablet:pb-52 tablet:pb-60 laptop:pb-72 large:pb-96' : 'pb-48')
          }
        >
          {/* Uses anchor link text to create an anchor link */}
          {blok.anchor_link && <a id={blok.anchor_link} className='element' />}

          <Title
            uniqueID={key}
            palette={palette}
            useSecondary={blok.use_secondary_color}
            text={submitted ? 'Thanks' : blok.headline}
            refParent={refParent}
          />

          <div className={'col-span-1 phablet:col-span-6 laptop:col-span-6'} />
          <div className={'col-span-5 phablet:col-span-6 laptop:col-span-5 rich-text-block'}>
            <div className={'w-full mt-8 mb-16 text-' + palette.text_main}>
              {' '}
              <AnimateInRichText uniqueID={key} text={test ? blok.successText : blok.intro_text} />
            </div>
          </div>
          <div className={'hidden laptop:block laptop:col-span-1 '} />

          <div className='hidden phablet:block phablet:col-span-1 laptop:col-span-3' />
          <div className='col-span-6 phablet:col-span-10 laptop:col-span-6'>
            <form
              className={
                'grid grid-cols-6 gap-y-16 phablet:grid-cols-6 gap-x-2 laptop:gap-x-4 large:gap-x-8' +
                (submitted ? ' form-submited' : '')
              }
              name='contact'
              method='post'
              // action={scrollToAnchor('#contact')}
              data-netlify='true'
              data-netlify-honeypot='bot-field'
              onSubmit={(e) => handleSubmit(e)}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type='hidden' required name='form-name' value='Contact' />
              <p hidden>
                <label>
                  Don’t fill this out: <input name='bot-field' onChange={handleChange} />
                </label>
              </p>
              {/* --------------------------------------------------------------------------------------- */}

              <div className='col-span-6 flex flex-col phablet:col-span-3'>
                <p>Name</p>
                <input
                  className={formClicked ? 'form-input  w-full form-input-clicked' : 'form-input  w-full'}
                  type='text'
                  required
                  name='name'
                  onChange={handleChange}
                />
                <span
                  className={formClicked ? 'form-input_error-msg_active' : 'form-input_error-msg_inactive'}
                >
                  * Please fill in your name
                </span>
              </div>

              <div className='col-span-6 flex flex-col phablet:col-span-3'>
                <p>Company</p>
                <input
                  className={formClicked ? 'form-input  w-full form-input-clicked' : 'form-input  w-full'}
                  type='text'
                  required
                  name='company'
                  onChange={handleChange}
                />
                <span
                  className={formClicked ? 'form-input_error-msg_active' : 'form-input_error-msg_inactive'}
                >
                  * Please fill in your company
                </span>
              </div>

              <div className='col-span-6 flex flex-col phablet:col-span-3'>
                <p>Email</p>
                <input
                  className={formClicked ? 'form-input  w-full form-input-clicked' : 'form-input  w-full'}
                  type='email'
                  required
                  name='email'
                  onChange={handleChange}
                />
                <span
                  className={formClicked ? 'form-input_error-msg_active' : 'form-input_error-msg_inactive'}
                >
                  * Please fill in a valid email adress
                </span>
              </div>

              <div className='col-span-6 form-field-phone flex flex-col phablet:col-span-3'>
                <p>Phone number (optional)</p>
                <div className='flex items-baseline w-full form_phone'>
                  <PhoneInput
                    placeholder='Enter phone number'
                    value={phoneValue}
                    onChange={handlePhoneChange}
                  />
                  {/* <select
                      className='form-input_select col-span-1'
                      type='email'
                      name='areaCode[]'
                      defaultValue='+31'
                      onChange={handleChange}
                    >
                      <option value='+31' defaultValue='+31'>
                        🇳🇱 +31
                      </option>
                      <option value='+33'>🇫🇷 +33</option>
                    </select>
                    <input
                      className={
                        formClicked ? 'form-input_phone w-full form-input-clicked' : 'input_phone w-full'
                      }
                      type='tel'
                      inputMode='numeric'
                      pattern='^[0-9-+\s()]{6,16}'
                      name='phoneNumber'
                      placeholder='2012345678'
                      onChange={handleChange}
                    />
                    <span className='form-input-phone_line'></span>
                    <span
                      className={
                        formClicked
                          ? 'form-input-phone_error-msg_active'
                          : 'form-input-phone_error-msg_inactive'
                      }
                    >
                      * Please fill in a valid phone number
                    </span> */}
                </div>
              </div>

              <div className='col-span-6 flex flex-col phablet:col-span-6'>
                <p>Message</p>
                <textarea
                  className={
                    formClicked
                      ? 'form-input_textarea  w-full form-input-clicked'
                      : 'form-input_textarea  w-full'
                  }
                  required
                  name='message'
                  onChange={handleChange}
                />
                <span
                  className={formClicked ? 'form-input_error-msg_active' : 'form-input_error-msg_inactive'}
                >
                  * Please write a message
                </span>
              </div>

              <div className='col-span-6 flex flex-col phablet:col-span-6'>
                <div className='w-full relative flex items-start'>
                  <input
                    className={
                      formClicked
                        ? 'form-input_checkbox  w-full form-input_checkbox-clicked'
                        : 'form-input_checkbox  w-full'
                    }
                    type='checkbox'
                    required
                    name='checkboxChecked'
                    onChange={handleChange}
                  />
                  <div className='w-full flex form-input_checkbox-txt'>
                    <p className='w-full inline'>
                      I have read and agree to the&nbsp;
                      <a className={'underline'} href={'/privacy'} target='_self' rel='noreferrer'>
                        privacy policy
                      </a>
                    </p>
                  </div>
                  <span
                    className={
                      formClicked
                        ? 'form-input_error-msg_active error-msg_checkbox absolute top-20 phablet:top-7'
                        : 'form-input_error-msg_inactive error-msg_checkbox absolute top-20 phablet:top-7'
                    }
                  >
                    * Please accept our privacy policy and our terms and conditions
                  </span>
                </div>
              </div>

              <div
                onClick={() => {
                  setFormClicked(true)
                }}
              >
                <Button
                  hasMargin={true}
                  link={'contact'}
                  submitButton={true}
                  text={'Send'}
                  isPrimary={true}
                />
              </div>
            </form>
          </div>
          <div className='hidden phablet:block phablet:col-span-1 laptop:col-span-3' />
        </div>
      </div>
    </SbEditable>
  )
}

export default FormContactNetlify;
