import gsap from "gsap";
import Img from "gatsby-image";

import React, { useEffect, useState, useRef } from "react";

import { getFluidGatsbyImage } from "gatsby-storyblok-image";
import { useLayout } from "../../hooks/useLayout";

import { useInView } from "react-intersection-observer";

export const MaskedImage = ({ uniqueID, image, fullHeight, maxWidth, maxHeight, objectFit, zoom, offsetBottom, offsetTop, revealWithScroll }) => {
    let fluidImageProps;
    if (image.filename !== null) {
        fluidImageProps = getFluidGatsbyImage(image.filename, {
            maxWidth: maxWidth || 2400,
            maxHeight: maxHeight || 1000,
            format: "webp",
            toFormat: "webp",
            quality: "80",
        });
    }

    // const ref = React.useRef(null);
    const imageRef = React.useRef(null);
    const [tl] = React.useState(gsap.timeline({ paused: true }));

    const elementID = "maskedimage_" + uniqueID;

    const { ref, inView } = useInView({ triggerOnce: true, rootMargin: "0px 0px -10% 0px" });

    React.useEffect(() => {
        tl.fromTo(
            imageRef.current.children,
            {
                clipPath: "polygon(100% 160%, 0% 160%, 0% 100%, 33% 100%, 33% 160%, 33% 150%, 66% 150%, 66% 200%, 66% 200%, 100% 200%)",
            },
            {
                clipPath: "polygon(100% 160%, 0% 160%, 0% 0%, 33% 0%, 33% 160%, 33% 0%, 66% 0%, 66% 160%, 66% 0%, 100% 0%)",
                duration: 1,
                ease: "Power2.easeInOut",
            }
        );

        if (zoom === true) {
            tl.fromTo(
                imageRef.current.children,
                { scale: 1.4 },
                {
                    scale: 1,
                    duration: 1,
                    ease: "Power2.easeInOut",
                },
                "<"
            );
        }
    }, []);

    MaskedImage.defaultProps = {
        objectFit: "cover",
        zoom: true,
        revealWithScroll: false,
    };

    useEffect(() => {
        if (inView) {
            tl.play();
        }
    }, [inView, tl]);

    return (
        <>
            {image.filename && (
                <>
                    <div
                        className={" h-full w-full "}
                        ref={ref}
                        data-scroll
                        data-scroll-call={elementID}
                        data-scroll-offset={(offsetBottom || "20") + "%," + (offsetTop || "70") + "%"}
                        data-scroll-id={elementID}>
                        <div ref={imageRef} className={"h-full w-full overflow-hidden"}>
                            <Img
                                className={"masked-image-wrapper " + (fullHeight ? "full-height" : "")}
                                objectFit={objectFit}
                                objectPosition='50% 50%'
                                fluid={fluidImageProps}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
