const MapStyles = [
  {
    'featureType': 'administrative',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#686868',
      },
    ],
  },
  {
    'featureType': 'administrative.locality',
    'elementType': 'labels.text',
    'stylers': [
      {
        'visibility': 'on',
      },
      {
        'hue': '#ff0000',
      },
    ],
  },
  {
    'featureType': 'administrative.locality',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#111c3e',
      },
    ],
  },
  {
    'featureType': 'administrative.locality',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'simplified',
      },
      {
        'color': '#111c3e',
      },
    ],
  },
  {
    'featureType': 'administrative.land_parcel',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#111c3e',
      },
    ],
  },
  {
    'featureType': 'landscape',
    'elementType': 'all',
    'stylers': [
      {
        'color': '#f2f2f2',
      },
    ],
  },
  {
    'featureType': 'poi',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off',
      },
    ],
  },
  {
    'featureType': 'poi',
    'elementType': 'labels.text',
    'stylers': [
      {
        'hue': '#ff0000',
      },
      {
        'visibility': 'off',
      },
    ],
  },
  {
    'featureType': 'road',
    'elementType': 'all',
    'stylers': [
      {
        'saturation': -100,
      },
      {
        'lightness': 45,
      },
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'simplified',
      },
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'lightness': '-22',
      },
      {
        'color': '#b0becc',
      },
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'saturation': '11',
      },
      {
        'lightness': '-51',
      },
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'labels.text',
    'stylers': [
      {
        'saturation': '3',
      },
      {
        'lightness': '-56',
      },
      {
        'weight': '2.20',
      },
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'lightness': '-52',
      },
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'weight': '6.13',
      },
    ],
  },
  {
    'featureType': 'road.highway',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'lightness': '-10',
      },
      {
        'gamma': '0.94',
      },
      {
        'weight': '1.24',
      },
      {
        'saturation': '-100',
      },
      {
        'visibility': 'off',
      },
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'geometry',
    'stylers': [
      {
        'lightness': '-16',
      },
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'visibility': 'on',
      },
      {
        'hue': '#ff0000',
      },
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'saturation': '-41',
      },
      {
        'lightness': '-41',
      },
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'weight': '5.46',
      },
    ],
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'off',
      },
    ],
  },
  {
    'featureType': 'road.local',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'weight': '0.72',
      },
      {
        'lightness': '-16',
      },
    ],
  },
  {
    'featureType': 'road.local',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'lightness': '-37',
      },
    ],
  },
  {
    'featureType': 'transit',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off',
      },
    ],
  },
  {
    'featureType': 'water',
    'elementType': 'all',
    'stylers': [
      {
        'color': '#b7e4f4',
      },
      {
        'visibility': 'on',
      },
    ],
  },
  {
    'featureType': 'water',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'color': '#9cd3ee',
      },
    ],
  },
]

export default MapStyles
