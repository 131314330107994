import { useWindowDimensions } from './useWindowDimensions';

export const useColumnWidth = () => {
  const { windowWidth } = useWindowDimensions();
  let columnWidth = 0;
  let gap = 0;

  if (windowWidth >= 1440) {
    gap = 32;
    columnWidth = 1440 / 12;
  }

  if (windowWidth >= 1024 && windowWidth < 1440) {
    gap = 15;
    columnWidth = (windowWidth - 30) / 12;
  }

  if (windowWidth < 1024) {
    gap = 5;
    columnWidth = (windowWidth - 30) / 6;
  }

  return {
    columnWidth,
    gap,
  };
}
